// @ts-strict-ignore
import _ from 'lodash';
import dispatchr from 'dispatchr';
import Baobab, { BaobabOptions } from 'baobab';
import { isProtractor } from '@/core/utilities';
import { BaobabEvent } from '@/core/flux.types';
import { AnyProperty } from '@/utilities.types';

// Original code was from the flux-angular package: https://github.com/christianalfoni/flux-angular

const immutableDefaults: Partial<BaobabOptions> = {
  immutable: process.env.NODE_ENV !== 'production', // Speed up production by not freezing objects
  asynchronous: process.env.NODE_ENV !== 'test' && !isProtractor(), // Changes to the tree emit synchronously
  pure: process.env.NODE_ENV !== 'test', // Allows dispatches to still trigger events even if value does not change
};

/**
 * @type PersistenceLevel
 * Different stores are persisted at different locations. Each persistence level is a group of stores that are
 * stored together
 *
 * NONE      - not saved, stored in memory only
 * WORKBENCH - per-user data store
 * WORKBOOK  - per-workbook data store
 * WORKSHEET - per-worksheet data store (in workstep)
 *
 * Every store should have a property called 'persistenceLevel' assigned one of PersistenceLevel.
 */
export type PersistenceLevel = 'NONE' | 'WORKBENCH' | 'WORKBOOK' | 'WORKSHEET';

export type InitializeMode = 'FORCE' | 'SOFT';

export type ValueOf<T> = T[keyof T];

export const PERSISTENCE_LEVELS = ['NONE', 'WORKBENCH', 'WORKBOOK', 'WORKSHEET'] as const;

export const PUSH_IGNORE = 'PUSH_IGNORE' as const;
export const PUSH_WORKSTEP_IMMEDIATE = 'PUSH_WORKSTEP_IMMEDIATE' as const;
export const PUSH_WORKBOOK = 'PUSH_WORKBOOK' as const;
export const PUSH_WORKBENCH = 'PUSH_WORKBENCH' as const;
export type PushOption =
  | typeof PUSH_IGNORE
  | typeof PUSH_WORKSTEP_IMMEDIATE
  | typeof PUSH_WORKBOOK
  | typeof PUSH_WORKBENCH;

export interface FluxStore<T extends {}> {
  handlers: Record<string, string>;

  exports: T;

  initialize: (initializeMode?: InitializeMode) => void;

  dehydrate?: () => any;

  rehydrate?: (any) => void;
}

export abstract class Store {
  static readonly storeName: string;
  readonly rehydrateWaitFor?: string[];
  private readonly dispatcher;
  private readonly immutableDefaults: Partial<BaobabOptions>;
  protected state: Baobab;
  abstract persistenceLevel: PersistenceLevel;
  protected abstract readonly handlers: Record<string, (payload: any) => void>;

  constructor(dispatcher) {
    this.dispatcher = dispatcher;
    this.immutableDefaults = dispatcher.getContext().immutableDefaults;
    this.initialize();
  }

  protected monkey = Baobab.monkey;

  protected immutable(initialState: any, options: Partial<BaobabOptions> = {}): Baobab {
    if (this.state) {
      this.state.set(initialState);
      return this.state;
    } else {
      return new Baobab(initialState, {
        ...this.immutableDefaults,
        ...options,
      });
    }
  }

  abstract initialize(initializeMode?: InitializeMode): void;

  rehydrate(state: AnyProperty) {}

  protected waitFor(stores: string | string[], callback: () => void) {
    stores = Array.isArray(stores) ? stores : [stores];
    try {
      stores.forEach((store) => this.dispatcher.getStore(store));
    } catch {
      throw new Error(`Waiting for stores that are not created is not allowed: ${stores.join(', ')}.`);
    }

    this.dispatcher.waitFor(stores, callback.bind(this));
  }
}

type DispatchFn = (action: string, payload?: any, option?: PushOption) => void;
type Dispatcher = {
  dispatch: DispatchFn;
  getStore: (store: any) => any;
  dehydrate: () => any;
  rehydrate: (any) => void;
  storeInstances: Record<string, Store>;
};

// Flux Service is a wrapper for the Yahoo Dispatchr
export class FluxService {
  public useEvalAsync = process.env.NODE_ENV !== 'test';
  stores: any[] = [];
  public readonly pendingStores: string[] = [];
  private dispatcherInstance;
  private immutableDefaults: Partial<BaobabOptions> = immutableDefaults;
  private dispatchCallback: DispatchFn = () => {};
  public dispatcher: Dispatcher;

  constructor() {
    this.dispatcherInstance = dispatchr.createDispatcher();
    this.dispatcher = this.dispatcherInstance.createContext({
      immutableDefaults: this.immutableDefaults,
    });
  }

  dispatch(handler: string, payload?: any, option?: PushOption) {
    if (this.pendingStores.length && process.env.NODE_ENV !== 'test') {
      throw new Error(`Stores have not yet been injected: ${this.pendingStores.join(', ')}`);
    }
    this.dispatcher.dispatch(handler, payload, option);
    this.dispatchCallback(handler, payload, option);
  }

  setDispatchCallback(callback: DispatchFn) {
    this.dispatchCallback = callback;
  }

  addPendingStore(storeName: string) {
    this.pendingStores.push(storeName);
  }

  createStore<T extends Store>(klazz: new (dispatcher) => T): T {
    const storeName = this.dispatcherInstance.getStoreName(klazz);
    // Needed to accommodate jest tests that load the flux stores again
    if (this.dispatcherInstance.isRegistered(storeName)) {
      return this.dispatcher.getStore(storeName);
    }

    this.dispatcherInstance.registerStore(klazz);
    const store = this.dispatcher.getStore(klazz);
    // We don't want the handlers to be static, so register them manually
    Object.keys(store.handlers).forEach((action) => {
      const handler = store.handlers[action];
      this.dispatcherInstance._registerHandler(action, storeName, handler);
    });
    return store;
  }

  /**
   * @deprecated
   */
  createStoreFromService(spec, storeName: string) {
    const flux = this;
    // Constructor of a yahoo dispatchr store
    const store: any = function (dispatcher) {
      this.dispatcher = dispatcher;

      // Check if store exists when waiting for it
      this.waitFor = function (stores, cb) {
        stores = Array.isArray(stores) ? stores : [stores];
        const storeNames = _.map(flux.stores, 'storeName');
        if (_.some(stores, (s) => !_.includes(storeNames, s))) {
          throw new Error(`Waiting for stores that are not created is not allowed: ${stores.join(', ')}.`);
        }
        this.dispatcher.waitFor(stores, cb.bind(this));
      };

      if (!this.initialize) {
        throw new Error(
          `Store ${storeName} does not have an initialize method which is is necessary to set the initial state`,
        );
      }

      this.initialize();
    };

    // Add constructor properties, as required by Yahoo Dispatchr
    store.handlers = spec.handlers;
    store.storeName = storeName;

    // Instantiates immutable state and saves it to private variable that can be used for setting listeners
    const defaults = this.immutableDefaults;
    store.prototype.immutable = function (initialState, options: Partial<BaobabOptions> = {}) {
      if (this.state) {
        this.state.set(initialState);
      } else {
        this.state = new Baobab(initialState, { ...defaults, ...options });
      }
      return this.state;
    };

    store.prototype.monkey = Baobab.monkey;

    // Attach store definition to the prototype
    Object.keys(spec).forEach((key) => {
      if (key !== 'state') {
        store.prototype[key] = spec[key];
      }
    });
    // eslint-disable-next-line no-prototype-builtins
    if (spec.hasOwnProperty('state')) {
      Object.getOwnPropertyNames(Object.getPrototypeOf(spec)).forEach((key) => {
        if (key !== 'constructor') {
          store.prototype[key] = spec[key];
        }
      });
    }

    store.exports = {};

    this.dispatcherInstance.registerStore(store);
    this.stores.push(store);
    _.pull(this.pendingStores, storeName);

    // Add cloning to exports
    const storeInstance = this.dispatcher.getStore(store);
    Object.keys(spec.exports).forEach((key) => {
      // Create a getter
      const descriptor = Object.getOwnPropertyDescriptor(spec.exports, key);
      if (descriptor.get) {
        Object.defineProperty(store.exports, key, {
          enumerable: descriptor.enumerable,
          configurable: descriptor.configurable,
          get: () => descriptor.get.apply(storeInstance),
        });
      } else {
        store.exports[key] = function () {
          return spec.exports[key].apply(storeInstance, arguments);
        };
        spec.exports[key] = spec.exports[key].bind(storeInstance);
      }
    });

    return store.exports;
  }

  listenTo(storeExport: any, callback: (baobabEvent: any) => void): () => void;
  listenTo(
    storeExport: any,
    mapping: string[] | undefined,
    callback: (baobabEvent: BaobabEvent) => void,
    useEvalAsync?: boolean,
  ): () => void;
  listenTo(storeExport, mapping?, callback?) {
    return listenToStore(this, storeExport, mapping, callback, this.useEvalAsync);
  }

  getStore(storeOrExports) {
    const storeIdentifier =
      storeOrExports.constructor.storeName ?? this.stores.find((s) => s.exports === storeOrExports);
    return this.dispatcher.getStore(storeIdentifier);
  }

  setImmutableDefaults(defaults: Partial<BaobabOptions>) {
    this.immutableDefaults = defaults;
  }

  reset() {
    this.dispatcherInstance.handlers = {};
    Object.keys(this.dispatcher.storeInstances).forEach((storeName) => {
      // Old stores will be re-injected and so must be removed
      if ((this.dispatcher.storeInstances[storeName].constructor as any).exports) {
        delete this.dispatcher.storeInstances[storeName];
        delete this.dispatcherInstance.stores[storeName];
      } else {
        const store = this.dispatcher.storeInstances[storeName];
        store.initialize('FORCE');
        Object.keys(store['handlers']).forEach((action) => {
          const handler = store['handlers'][action];
          this.dispatcherInstance._registerHandler(action, storeName, handler);
        });
      }
    });
    this.stores = [];
  }
}

function listenToStore(flux, storeExport, mapping, callback, useEvalAsync) {
  const store = flux.getStore(storeExport);

  if (!store.state) {
    throw new Error(
      `Store ${storeExport.storeName} has not defined state with this.immutable() which is required in order to use $listenTo`,
    );
  }

  if (!callback) {
    callback = mapping;
    mapping = undefined;
  }

  const cursor = mapping ? store.state.select(mapping) : store.state;

  const originalCallback = callback;
  if (useEvalAsync) {
    callback = (e) => {
      originalCallback(e);
    };
  }

  cursor.on('update', callback);

  return () => cursor.off('update', callback);
}
